import { SHAssignmentViewCJMMRecord } from '../reports/sh/models';

export const mockSHAssignmentViewCJMMRecord: SHAssignmentViewCJMMRecord[] = [
  {
    studentId: 100,
    studentName: 'Kim, Vu',
    recognizeCues: 89.5,
    analyzeCues: 92.5,
    prioritizeHypothesis: 92.5,
    generateSolutions: 89.5,
    evaluateOutcomes: 89.5,
    takeActions: 89.5
  },
  {
    studentId: 101,
    studentName: 'Green, Zachary',
    recognizeCues: 56.4,
    analyzeCues: 89.5,
    prioritizeHypothesis: 56.4,
    generateSolutions: 89.5,
    evaluateOutcomes: 56.4,
    takeActions: 89.5
  },
  {
    studentId: 102,
    studentName: 'Heaney, Garth',
    recognizeCues: 19.2,
    analyzeCues: 19.2,
    prioritizeHypothesis: 77.4,
    generateSolutions: 19.2,
    evaluateOutcomes: 77.4,
    takeActions: 67.3
  },
  {
    studentId: 103,
    studentName: 'Kirsch, Allan',
    recognizeCues: 89.5,
    analyzeCues: 92.5,
    prioritizeHypothesis: 92.5,
    generateSolutions: 89.5,
    evaluateOutcomes: 89.5,
    takeActions: 89.5
  },
  {
    studentId: 104,
    studentName: 'Montgomery, Addison',
    recognizeCues: 56.4,
    analyzeCues: 56.4,
    prioritizeHypothesis: 89.5,
    generateSolutions: 89.5,
    evaluateOutcomes: 56.4,
    takeActions: 89.5
  },
  {
    studentId: 105,
    studentName: 'Rodriguez - Alvarez, Maria',
    recognizeCues: 77.4,
    analyzeCues: 77.4,
    prioritizeHypothesis: 19.2,
    generateSolutions: 19.2,
    evaluateOutcomes: 67.3,
    takeActions: 19.2
  }
];
