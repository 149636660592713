import { memo, useEffect, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import cx from 'classnames';

import { appActions } from 'redux/ducks/app';
import { ProgramSolutionsNavMenu } from 'components/common/ps-nav-menu/ProgramSolutionsNavMenu';
import CourseSelectionModal, { CourseSelectionModalProps } from 'components/common/course-selection-modal/CourseSelectionModal';

import { Program, CourseSectionInfo } from 'reports/had/models';

import { BentoBoxProps } from '../bento-box/BentoBox';
import useBentoBoxModal from './useBentoBoxModal';

export interface BentoBoxModalProps {
  courses: CourseSectionInfo[];
  hadPrograms?: Program[];
  isHesiReadinessApp: boolean;
  isEAQTopicMappedHesiExamLoading: boolean;
  isCWLinkHADAndEHRChecking: boolean;
  selectedCourse: number;
  visible?: boolean;
  showCourseSelectionModal: boolean;
  handleCourseSelectionModal?: Function;
  onClickOutSide?: Function;
  instructorId?: number;
  autoHideBento?: boolean;
  isProgramSolutionsEnabled: boolean;
}

export const BentoBoxModal = ({
  visible = false,
  courses,
  onClickOutSide,
  isEAQTopicMappedHesiExamLoading,
  isCWLinkHADAndEHRChecking,
  isHesiReadinessApp,
  hadPrograms,
  handleCourseSelectionModal,
  selectedCourse,
  showCourseSelectionModal,
  instructorId,
  autoHideBento = false,
  isProgramSolutionsEnabled
}: BentoBoxModalProps) => {
  const dispatch = useDispatch();

  const {
    isCWDisable,
    isEHRDisable,
    isHADDisable,
    onEHRNavigation,
    onHADNavigation,
    onCWNavigation,
    handleRedirectPage,
    isCWBentoBoxLinkLoading,
    courseSectionsByReport,
    onCloseCourseSelectionModal,
    isPRDDisable,
    onPRDNavigation,
    onPSNavigation
  } = useBentoBoxModal({
    courses,
    isHesiReadinessApp,
    hadPrograms,
    handleCourseSelectionModal,
    instructorId,
    onClickOutSide
  });
  const bentoBoxRef = useRef<HTMLDivElement>();

  const onClickOutSideBentoBox = useCallback(
    event => {
      if (onClickOutSide && bentoBoxRef.current && !bentoBoxRef.current.contains(event.target)) {
        return onClickOutSide();
      }
      return false;
    },
    [onClickOutSide]
  );

  useEffect(() => {
    document.addEventListener('mousedown', onClickOutSideBentoBox);
    return () => {
      document.removeEventListener('mousedown', onClickOutSideBentoBox);
    };
  }, [onClickOutSideBentoBox]);

  const bentoBoxProps: BentoBoxProps = {
    CW: {
      disabled: isCWDisable,
      onNavigation: onCWNavigation,
      loading: isCWBentoBoxLinkLoading
    },
    HAD: {
      disabled: isHADDisable,
      onNavigation: onHADNavigation,
      loading: isCWLinkHADAndEHRChecking
    },
    EHR: {
      disabled: isEHRDisable,
      onNavigation: onEHRNavigation,
      loading: isEAQTopicMappedHesiExamLoading
    },
    PRD: {
      disabled: isPRDDisable,
      onNavigation: onPRDNavigation,
      loading: false
    },
    PS: {
      disabled: !isProgramSolutionsEnabled,
      onNavigation: onPSNavigation,
      loading: false
    },
    onClickOutSide
  };

  const courseSelectionModalProps: CourseSelectionModalProps = {
    onCloseModal: onCloseCourseSelectionModal,
    courseSections: courseSectionsByReport,
    initialCourseId: selectedCourse,
    handleRedirectPage
  };

  if (autoHideBento && !(isCWBentoBoxLinkLoading && isCWLinkHADAndEHRChecking && isEAQTopicMappedHesiExamLoading)) {
    if (isCWDisable && isHADDisable && isEHRDisable) {
      dispatch(appActions.setBentoBoxShown(false));
      return <div />;
    }
    dispatch(appActions.setBentoBoxShown(true));
  }

  return (
    <>
      {visible && (
        <div className="c-els-modal">
          <div ref={bentoBoxRef} role="button" className={cx('c-els-modal__window', { 'u-els-width-1o2': isProgramSolutionsEnabled })}>
            <ProgramSolutionsNavMenu {...bentoBoxProps} />
          </div>
        </div>
      )}
      {showCourseSelectionModal && <CourseSelectionModal {...courseSelectionModalProps} />}
    </>
  );
};

export default memo(BentoBoxModal);
