import { matchPath } from 'react-router';
import Immutable from 'seamless-immutable';
import { createAction, handleActions } from 'redux-actions';
import { createSelector } from 'reselect';
import { ELSCommonUIConstants, ELSLoggingService, ELSTokenHelper } from '@els/els-ui-common-react';
import { AppConstant, FeatureFlagGroupNames, FeatureFlagNames, USER_ROLE } from 'constants/app.constant';
import { SystemType } from 'models';
import { appActions } from 'redux/ducks/app';
import { courseActions } from 'reports/cw/redux/ducks/courses';
import { fetchCourseSectionInfo } from 'reports/cw/services/courses.service';
import { DEFAULT_PROGRAM_ID, EVOLVE_EDUCATION_SITE, HAD_PATHS, EXAM_CUSTOM_TYPE } from 'reports/had/constants/had.constant';
import { Cohort, Program } from 'reports/had/models';
import { buildCategoryGroupOptions, getDefaultCategoryGroupId } from 'reports/had/services/category.service';
import {
  fetchPrograms as fetchProgramsService,
  fetchCohortsByProgramIds as fetchCohortsByProgramIdsService,
  fetchCategoryGroupList,
  fetchStudentDetailByProgram,
  fetchStudentDetailByProgramV2,
  fetchDemoStudentDetailByProgram,
  fetchDemoStudentDetailByProgramV2,
  fetchLegacyStudentDetailByProgram,
  fetchStudentDetailByEvolveUsername,
  fetchStudentDetailByEvolveUsernameV2,
  fetchCategoryGroupListDwh
} from 'reports/had/services/had.service';
import { trackAAEvent } from 'services/adobe-analytics.service';
import { fetchFeatureFlags, isFeatureEnabled } from 'services/feature-flag.service';
import { fetchCrosswalkUser } from 'services/user-management.service';

const initialState = Immutable({
  programs: [],
  programId: 0,
  programName: '',
  cohorts: [],
  selectedCohort: {} as Cohort,
  cohortExams: [],
  cohortStudents: [],
  examStudents: [],
  categoryGroupList: [],
  categoryGroupOptions: [],
  categoryGroupSelectedKey: '',
  isTrendLineGraphHidden: false,
  isSubCategoryChangedBySelectingDropdown: false,
  demoEvolveUsername: '',
  isProgramsFetching: false,
  isCohortsFetching: false,
  isCategoryGroupListFetching: false,
  isStudentMultipleCohortEnabled: false,
  isStudentMultipleCohortShow: false,
  selectCourseNGNData: {},
  studentDetail: {},
  isHADStudentFetching: true,
  studentDetailOriginPath: '',
  isInsViewStuPage: false,
  isInsViewStuPageByDefault: false,
  isNgnItemFetching: false,
  featureFlags: [],
  isNavigateFromPS: false
});

const restoreState = createAction('HAD/RESTORE');
const setPrograms = createAction('HAD/SET_PROGRAMS');
const setProgramId = createAction('HAD/SET_PROGRAM_ID');
const setProgramName = createAction('HAD/SET_PROGRAM_NAME');
const setCohorts = createAction('HAD/SET_COHORTS');
const setCohortExams = createAction('HAD/SET_COHORT_EXAMS');
const setCohortStudents = createAction('HAD/SET_COHORT_STUDENTS');
const setExamStudents = createAction('HAD/SET_EXAM_STUDENTS');
const setSelectedCohort = createAction('HAD/SET_SELECTED_COHORT');
const setCategoryGroupList = createAction('HAD/SET_CATEGORY_GROUP_LIST');
const setCategoryGroupOptions = createAction('HAD/SET_CATEGORY_GROUP_OPTIONS');
const setCategoryGroupSelectedKey = createAction('HAD/SET_CATEGORY_GROUP_SELECTED_KEY');
const setIsTrendLineGraphHidden = createAction('HAD/SET_IS_TREND_LINE_GRAPH_HIDDEN');
const setIsSubCategoryChangedBySelectingDropdown = createAction('HAD/SET_IS_SUB_CATEGORY_CHANGED_BY_SELECTING_DROPDOWN');
const setDemoEvolveUsername = createAction('HAD/SET_DEMO_EVOLVE_USERNAME');
const setIsProgramsFetching = createAction('HAD/SET_IS_PROGRAMS_FETCHING');
const setIsCohortsFetching = createAction('HAD/SET_IS_COHORTS_FETCHING');
const setIsCategoryGroupFetching = createAction('HAD/SET_IS_CATEGORY_GROUP_FETCHING');
const setIsHADStudentFetching = createAction('HAD/SET_IS_HAD_STUDENT_FETCHING');
const setIsStudentMultipleCohortEnabled = createAction('HAD/SET_IS_STUDENT_MULTIPLE_COHORT_ENABLED');
const setIsStudentMultipleCohortShow = createAction('HAD/SET_IS_STUDENT_MULTIPLE_COHORT_SHOW');
const setSelectCourseNGNData = createAction('HAD/SET_SELECT_COURSE_NGN_DATA');
const setStudentDetail = createAction('HAD/SET_STUDENT_DETAIL');
const setStudentDetailOriginPath = createAction('HAD/SET_STUDENT_DETAIL_ORIGIN_PATH');
const setIsInsViewStuPage = createAction('HAD/SET_IS_INS_VIEW_STU_PAGE');
const setIsInsViewStuPageByDefault = createAction('HAD/SET_IS_INS_VIEW_STU_PAGE_BY_DEFAULT');
const setIsNGNItemFetching = createAction('HAD/SET_IS_NGN_ITEM_FETCHING');
const setIsNavigateFromPS = createAction('HAD/SET_IS_NAVIGATE_FROM_PS');

const resetCohortStoredData = () => dispatch => {
  dispatch(setSelectedCohort({} as Cohort));
  dispatch(setCohortExams([]));
  dispatch(setCohortStudents([]));
};

const fetchCohorts = (programId: number, evolveUsername?: string) => dispatch => {
  dispatch(setIsCohortsFetching(true));
  fetchCohortsByProgramIdsService([programId], evolveUsername)
    .then(cohorts => {
      dispatch(setCohorts(cohorts || []));
    })
    .catch(() => {
      dispatch(setCohorts([]));
    })
    .finally(() => {
      dispatch(setIsCohortsFetching(false));
    });
};

const getSelectedProgramId = (programs: Array<Program>, getState): number => {
  const hadState = getState()[AppConstant.reduxResources.HAD_STATE];
  if (hadState.programId && programs.find(program => program.programId === hadState.programId)) {
    return hadState.programId;
  }
  return programs.length ? programs[0].programId : DEFAULT_PROGRAM_ID;
};

const getSelectedProgramName = (programs: Array<Program>, getState): string => {
  const hadState = getState()[AppConstant.reduxResources.HAD_STATE];
  if (hadState.programId && programs.find(program => program.programId === hadState.programId)) {
    return hadState.programName;
  }
  return programs.length ? programs[0].programName : '';
};

const updateAnalyticsAccountInfo = (programId: number, programName: string) => (dispatch, getState) => {
  const appState = getState()[AppConstant.reduxResources.APP_STATE];
  const { evolveUser } = appState;
  const prefix = evolveUser?.evolveUserId ? `evo` : `eols`;

  trackAAEvent(ELSCommonUIConstants.analytics.eventTypes.contentView, {
    userId: `${prefix}:${evolveUser?.eolsId || evolveUser?.evolveUserId}`,
    accountId: `${prefix}:${programId}`,
    accountName: `${prefix}:${programName}`
  });
};

const redirectToEvolveEducationSite = () => {
  window.location.href = EVOLVE_EDUCATION_SITE;
};

const fetchProgramsHandler = async (dispatch, getState, isPNProgramHidden = false) => {
  // evolveUsername: from PRD, evolveUser from others
  const { evolveUsername = null, evolveUser = { username: null }, featureFlags = [] } = getState()[AppConstant.reduxResources.APP_STATE];
  const { programs: hadPrograms = [] as Program[] } = getState()[AppConstant.reduxResources.HAD_STATE];
  const { instructorId = null } = getState()[AppConstant.reduxResources.SH_STATE];

  let programs: Program[] = hadPrograms;
  if (!programs.length) {
    try {
      const { data = [] } = await fetchProgramsService(evolveUsername);
      programs = data;
    } catch (e) {
      redirectToEvolveEducationSite();
    }
  }

  if (isPNProgramHidden) {
    programs = programs.filter(program => !program.programTypeName.includes('PN'));
  }

  if (!programs.length) {
    redirectToEvolveEducationSite();
  }

  const programId = getSelectedProgramId(programs, getState);
  const programName = getSelectedProgramName(programs, getState);

  dispatch(setPrograms({ data: programs }));
  dispatch(setProgramId(programId));
  dispatch(setProgramName(programName));
  dispatch(setIsProgramsFetching(false));
  if (programId) {
    try {
      const cohortsPayload = await fetchCohortsByProgramIdsService([programId], evolveUsername);
      dispatch(setCohorts(cohortsPayload || []));
    } catch {
      dispatch(setCohorts([]));
    } finally {
      dispatch(setIsCohortsFetching(false));
      dispatch(updateAnalyticsAccountInfo(programId, programName));
    }
  } else {
    dispatch(setIsCohortsFetching(false));
  }

  if (!instructorId) {
    const isCWHADPRDLinkEnabled = isFeatureEnabled(featureFlags, FeatureFlagNames.CW_HAD_PRD_LINK_ENABLED);
    if (isCWHADPRDLinkEnabled) {
      dispatch(appActions.fetchSHInstructorId(evolveUser.userName));
    }
  }
};

const fetchHESIDashboardData = () => async (dispatch, getState) => {
  dispatch(setIsProgramsFetching(true));
  dispatch(setIsCohortsFetching(true));
  const { data } = await fetchFeatureFlags();
  let appState = getState()[AppConstant.reduxResources.APP_STATE];
  appState = { ...appState, featureFlags: data };
  const { loggedInUser, evolveUser, evolveUserLoading, evolveUserHasBeenCalled, featureFlags } = appState;
  const isPNProgramHidden = isFeatureEnabled(featureFlags, FeatureFlagNames.HAD_PN_PROGRAM_HIDDEN, FeatureFlagGroupNames.HAD_PN_PROGRAM_HIDDEN_GROUP);
  const userId = loggedInUser ? loggedInUser.userId || loggedInUser.id : undefined;
  if (userId !== evolveUser?.eolsId && !evolveUserLoading && !evolveUserHasBeenCalled) {
    // We have to fetch evolveUser because in HESI-NG app, the token does not have userName field
    appActions.fetchEvolveUser(userId);
  }
  await fetchProgramsHandler(dispatch, getState, isPNProgramHidden);
};

export const fetchCategoryGroupListData = (cohortId: number, isStudentRole?: boolean) => async (dispatch, getState) => {
  try {
    const { featureFlags = [] } = getState()[AppConstant.reduxResources.APP_STATE];
    const isCategoryGroupDwhEnable = isFeatureEnabled(featureFlags, FeatureFlagNames.HAD_CATEGORY_GROUP_DWH_ENABLE);
    dispatch(setIsCategoryGroupFetching(true));
    const categoryGroupResponse = isCategoryGroupDwhEnable ? await fetchCategoryGroupListDwh(cohortId) : await fetchCategoryGroupList(cohortId);
    dispatch(setCategoryGroupList(categoryGroupResponse.data || []));
    const categoryGroupOptions = buildCategoryGroupOptions(categoryGroupResponse.data || [], isStudentRole);
    dispatch(setCategoryGroupOptions(categoryGroupOptions));
    const hadState = getState()[AppConstant.reduxResources.HAD_STATE];
    dispatch(setCategoryGroupSelectedKey(hadState?.categoryGroupSelectedKey || getDefaultCategoryGroupId(categoryGroupOptions, isStudentRole)));
  } catch (err) {
    dispatch(setCategoryGroupList([]));
    dispatch(setCategoryGroupOptions([]));
    dispatch(setCategoryGroupSelectedKey(''));
    ELSLoggingService.error('had.ts', `Failed to fetch categories group list: ${err.message}`);
  } finally {
    dispatch(setIsCategoryGroupFetching(false));
  }
};

const fetchHADStudentData = (evolveUserNameParam = '') => async (dispatch, getState) => {
  dispatch(setIsHADStudentFetching(true));
  let appState = getState()[AppConstant.reduxResources.APP_STATE];

  if (!appState.featureFlags?.length) {
    const { data } = await fetchFeatureFlags();
    appState = { ...appState, featureFlags: data };
  }

  const { loggedInUser, selectedCourse, userRole, featureFlags } = appState;
  try {
    let { evolveUser } = appState;
    const isHadStudentSummaryNewTableEnable = isFeatureEnabled(featureFlags, FeatureFlagNames.HAD_STUDENT_SUMMARY_NEW_TABLE);
    const userId = loggedInUser ? loggedInUser.userId || loggedInUser.id : undefined;
    // TODO: safely remove this. It should be set in IntegrationLoginComponent
    if (userId !== evolveUser?.eolsId) {
      // We have to fetch evolveUser because in HESI-NG app, the token does not have userName field
      const crosswalkUser = await fetchCrosswalkUser(userId, SystemType.EVOLVETYPE);
      evolveUser = crosswalkUser.data;
      dispatch(appActions.setEvolveUser(evolveUser));
    }
    const token = ELSTokenHelper.getToken();
    const hadState = getState()[AppConstant.reduxResources.HAD_STATE];
    const { isInsViewStuPageByDefault, categoryGroupOptions } = hadState;
    const decodeToken = ELSTokenHelper.parseToken(token);
    const appParams = decodeToken?.user?.appParams;
    let programId = hadState?.programId;
    if (!programId) {
      programId = appParams?.programCode;
      dispatch(setProgramId(programId));
    }

    // TODO: safely remove this. It should be set in IntegrationLoginComponent
    if (!selectedCourse) {
      const courseId = decodeToken?.user?.appParams?.courseId;
      dispatch(appActions.setSelectedCourse(courseId));
      const courseSectionInfoResponse = await fetchCourseSectionInfo(courseId);
      dispatch(courseActions.setCourseSectionInfo(courseSectionInfoResponse.data));
    }

    const studentEvolveUserName = appParams?.studentEvolveUserName;
    const isStudentRole = userRole === USER_ROLE.STUDENT;
    const isInsViewStuPage = !!(userRole === USER_ROLE.EOLS_INSTRUCTOR && studentEvolveUserName);
    dispatch(setIsInsViewStuPage(isInsViewStuPage));
    const evolveUserName = isInsViewStuPage ? studentEvolveUserName : evolveUser?.userName;
    const { demoEvolveUsername } = hadState;
    let studentDetailRes;
    let studentDetail;
    if (demoEvolveUsername) {
      const fetchDemoStudentDetailByProgramByFlag = isHadStudentSummaryNewTableEnable ? fetchDemoStudentDetailByProgramV2 : fetchDemoStudentDetailByProgram;
      // work for demo purpose from launch-pad
      studentDetailRes = await fetchDemoStudentDetailByProgramByFlag(demoEvolveUsername, programId);
      studentDetail = studentDetailRes.data;
      const filterStudentExams = studentDetail.studentExams.filter(e => e.examType !== EXAM_CUSTOM_TYPE);
      studentDetail = {
        ...studentDetail,
        studentExams: filterStudentExams,
        examCount: filterStudentExams.length
      };
    } else {
      const isDwhFetchStudentDetailByProgramApiEnabled = isFeatureEnabled(featureFlags, FeatureFlagNames.DWH_STUDENT_DETAIL_BY_PROGRAM_API_ENABLED);
      const isStudentMultiProgram = isFeatureEnabled(featureFlags, FeatureFlagNames.HAD_STUDENT_VIEW_MULTI_PROGRAM);
      const fetchStudentDetailByEvolveUsernameByFlag = isHadStudentSummaryNewTableEnable ? fetchStudentDetailByEvolveUsernameV2 : fetchStudentDetailByEvolveUsername;
      const fetchStudentDetailByProgramByFlag = isHadStudentSummaryNewTableEnable ? fetchStudentDetailByProgramV2 : fetchStudentDetailByProgram;
      const studentDetailApi = isDwhFetchStudentDetailByProgramApiEnabled ? fetchStudentDetailByProgramByFlag : fetchLegacyStudentDetailByProgram;
      if (isStudentRole && isStudentMultiProgram) {
        studentDetailRes = await fetchStudentDetailByEvolveUsernameByFlag(evolveUserName);
        studentDetail = studentDetailRes.data;
      } else {
        studentDetailRes = await studentDetailApi(evolveUserNameParam || evolveUserName, programId);
        studentDetail = studentDetailRes.data;
      }
    }
    dispatch(setStudentDetail(studentDetail));
    const cohortId = studentDetailRes?.data?.cohorts?.find(e => !!e)?.cohortId;
    if (cohortId) {
      dispatch(fetchCategoryGroupListData(cohortId, true));
      if (isInsViewStuPageByDefault) {
        dispatch(setCategoryGroupSelectedKey(getDefaultCategoryGroupId(categoryGroupOptions, isInsViewStuPageByDefault)));
      }
    }
  } catch (err) {
    ELSLoggingService.error('had.ts', `Failed to fetch HAD Student Data: ${err.message}`);
  } finally {
    dispatch(setIsHADStudentFetching(false));
  }
};

const changeIsInstructorViewAsStudent = isViewAsStudent => async (dispatch, getState) => {
  const routeState = getState()[AppConstant.reduxResources.ROUTER];
  const hadSelectors = getState()[AppConstant.reduxResources.HAD_STATE];
  const { isInsViewStuPageByDefault } = hadSelectors;

  if (routeState.location) {
    const isPathMatch = matchPath(HAD_PATHS.studentHome, routeState.location.pathname);
    if (!isPathMatch?.isExact && isInsViewStuPageByDefault) {
      dispatch(setIsInsViewStuPageByDefault(isViewAsStudent));
      dispatch(setCategoryGroupSelectedKey(getDefaultCategoryGroupId(hadSelectors.categoryGroupOptions, false)));
    }
  }
};

const actions = {
  restoreState,
  resetCohortStoredData,
  getSelectedProgramId,
  getSelectedProgramName,
  setPrograms,
  setProgramId,
  setProgramName,
  setCohorts,
  setSelectedCohort,
  setSelectCourseNGNData,
  setStudentDetail,
  setStudentDetailOriginPath,
  setCohortExams,
  setExamStudents,
  setCohortStudents,
  setCategoryGroupList,
  setCategoryGroupOptions,
  setCategoryGroupSelectedKey,
  setIsTrendLineGraphHidden,
  setIsSubCategoryChangedBySelectingDropdown,
  setDemoEvolveUsername,
  setIsProgramsFetching,
  setIsCohortsFetching,
  setIsCategoryGroupFetching,
  setIsHADStudentFetching,
  setIsStudentMultipleCohortEnabled,
  setIsStudentMultipleCohortShow,
  setIsInsViewStuPage,
  setIsInsViewStuPageByDefault,
  setIsNGNItemFetching,
  fetchHESIDashboardData,
  fetchCohorts,
  fetchCategoryGroupListData,
  fetchHADStudentData,
  updateAnalyticsAccountInfo,
  changeIsInstructorViewAsStudent,
  setIsNavigateFromPS
};

const reducer = handleActions(
  {
    [actions.restoreState]: (state, { payload }) => state.merge(payload),
    [actions.setPrograms]: (state, { payload }) => Immutable.set(state, 'programs', payload.data),
    [actions.setProgramId]: (state, { payload }) => Immutable.set(state, 'programId', payload),
    [actions.setProgramName]: (state, { payload }) => state.set('programName', payload),
    [actions.setCohorts]: (state, { payload }) => Immutable.set(state, 'cohorts', payload.data),
    [actions.setSelectedCohort]: (state, { payload }) => Immutable.set(state, 'selectedCohort', payload),
    [actions.setSelectCourseNGNData]: (state, { payload }) => Immutable.set(state, 'selectCourseNGNData', payload),
    [actions.setStudentDetail]: (state, { payload }) => Immutable.set(state, 'studentDetail', payload),
    [actions.setStudentDetailOriginPath]: (state, { payload }) => Immutable.set(state, 'studentDetailOriginPath', payload),
    [actions.setCohortExams]: (state, { payload }) => Immutable.set(state, 'cohortExams', payload),
    [actions.setCohortStudents]: (state, { payload }) => Immutable.set(state, 'cohortStudents', payload),
    [actions.setCategoryGroupList]: (state, { payload }) => Immutable.set(state, 'categoryGroupList', payload),
    [actions.setCategoryGroupOptions]: (state, { payload }) => Immutable.set(state, 'categoryGroupOptions', payload),
    [actions.setCategoryGroupSelectedKey]: (state, { payload }) => Immutable.set(state, 'categoryGroupSelectedKey', payload),
    [actions.setIsTrendLineGraphHidden]: (state, { payload }) => Immutable.set(state, 'isTrendLineGraphHidden', payload),
    [actions.setIsSubCategoryChangedBySelectingDropdown]: (state, { payload }) => Immutable.set(state, 'isSubCategoryChangedBySelectingDropdown', payload),
    [actions.setDemoEvolveUsername]: (state, { payload }) => Immutable.set(state, 'demoEvolveUsername', payload),
    [actions.setIsProgramsFetching]: (state, { payload }) => Immutable.set(state, 'isProgramsFetching', payload),
    [actions.setIsCohortsFetching]: (state, { payload }) => Immutable.set(state, 'isCohortsFetching', payload),
    [actions.setIsHADStudentFetching]: (state, { payload }) => Immutable.set(state, 'isHADStudentFetching', payload),
    [actions.setIsStudentMultipleCohortEnabled]: (state, { payload }) => Immutable.set(state, 'isStudentMultipleCohortEnabled', payload),
    [actions.setIsStudentMultipleCohortShow]: (state, { payload }) => Immutable.set(state, 'isStudentMultipleCohortShow', payload),
    [actions.setIsCategoryGroupFetching]: (state, { payload }) => Immutable.set(state, 'isCategoryGroupFetching', payload),
    [actions.setIsInsViewStuPage]: (state, { payload }) => Immutable.set(state, 'isInsViewStuPage', payload),
    [actions.setIsInsViewStuPageByDefault]: (state, { payload }) => Immutable.set(state, 'isInsViewStuPageByDefault', payload),
    [actions.setIsNGNItemFetching]: (state, { payload }) => Immutable.set(state, 'isNgnItemFetching', payload),
    [actions.setExamStudents]: (state, { payload }) => Immutable.set(state, 'examStudents', payload),
    [actions.setIsNavigateFromPS]: (state, { payload }) => Immutable.set(state, 'isNavigateFromPS', payload)
  },
  initialState
);

const getHadState = state => state[AppConstant.reduxResources.HAD_STATE];

const selectors = {
  getPrograms: createSelector(getHadState, state => state.programs),
  getProgramId: createSelector(getHadState, state => state.programId),
  getProgramName: createSelector(getHadState, state => state.programName),
  getCohorts: createSelector(getHadState, state => state.cohorts),
  getSelectedCohort: createSelector(getHadState, state => state && state.selectedCohort),
  getCohortExams: createSelector(getHadState, state => state.cohortExams),
  getCohortStudents: createSelector(getHadState, state => state.cohortStudents),
  getExamStudents: createSelector(getHadState, state => state.examStudents),
  getCategoryGroupList: createSelector(getHadState, state => state.categoryGroupList),
  getCategoryGroupOptions: createSelector(getHadState, state => state.categoryGroupOptions),
  getCategoryGroupSelectedKey: createSelector(getHadState, state => state.categoryGroupSelectedKey),
  getIsTrendLineGraphHidden: createSelector(getHadState, state => state.isTrendLineGraphHidden),
  getIsSubCategoryChangedBySelectingDropdown: createSelector(getHadState, state => state.isSubCategoryChangedBySelectingDropdown),
  getDemoEvolveUsername: createSelector(getHadState, state => state.demoEvolveUsername),
  getIsProgramsFetching: createSelector(getHadState, state => state.isProgramsFetching),
  getIsCohortsFetching: createSelector(getHadState, state => state.isCohortsFetching),
  getIsCategoryGroupFetching: createSelector(getHadState, state => state.isCategoryGroupFetching),
  getIsHADStudentFetching: createSelector(getHadState, state => state.isHADStudentFetching),
  getIsStudentMultipleCohortEnabled: createSelector(getHadState, state => state.isStudentMultipleCohortEnabled),
  getIsStudentMultipleCohortShow: createSelector(getHadState, state => state.isStudentMultipleCohortShow),
  getSelectCourseNGNData: createSelector(getHadState, state => state.selectCourseNGNData),
  getStudentDetail: createSelector(getHadState, state => state.studentDetail),
  getStudentDetailOriginPath: createSelector(getHadState, state => state.studentDetailOriginPath),
  getIsInsViewStuPage: createSelector(getHadState, state => state.isInsViewStuPage && !state.isInsViewStuPageByDefault),
  getIsInsViewStuPageByDefault: createSelector(getHadState, state => state.isInsViewStuPageByDefault),
  getNGNItemList: createSelector(getHadState, state => state.ngnItemList),
  getIsNGNItemFetching: createSelector(getHadState, state => state.isNgnItemFetching),
  getIsNavigateFromPS: createSelector(getHadState, state => state.isNavigateFromPS)
};

export { actions as hadActions, reducer as hadReducer, selectors as hadSelectors };
export { initialState as hadInitialState };
