import { rest } from 'msw';
import { studentsByEvolveUserName, mockSHAssignmentViewCJMMRecord } from 'mock-data';

export const handlers = [
  rest.get('/sh-assignment-cjmm', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockSHAssignmentViewCJMMRecord));
  }),
  rest.get('https://eolsapi.elsevier.com/api/performancereport/allCourses/students', (req, res, ctx) => {
    const queryParams = req.url.searchParams;
    const evolveUserName = queryParams.get('evolveUserName');

    if (!evolveUserName) {
      req.passthrough();
    }

    return res(ctx.status(200), ctx.json(studentsByEvolveUserName));
  })
];
