import { Component } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ELSWithModalService } from '@els/els-component-modal-react';
import { Location } from 'history';
import { routerSelectors } from 'redux/ducks/route';
import { ELSModalServiceType } from 'models';
import { getAdobeProductCode } from 'helpers/app.helper';

interface LocationChangeHandlerProps {
  location: Location;
  modalService: ELSModalServiceType;
}

const pathname = 'pathname';

class LocationChangeHandler extends Component<LocationChangeHandlerProps> {
  componentDidMount() {
    this.updateAdobeProductCodeByLocation();
  }

  componentDidUpdate({ location }) {
    if (location && this.props.location && location[pathname] !== this.props.location[pathname]) {
      this.props.modalService.closeAllModal();
      this.updateAdobeProductCodeByLocation();
    }
    /* eslint-disable no-underscore-dangle */
    window._mfq = window._mfq || [];
    const regexp = new RegExp('/#', 'g');
    const href = window.location.href.replace(regexp, '');
    window._mfq.push(['newPageView', href]);
  }

  updateAdobeProductCodeByLocation = () => {
    const adobeProductCode = getAdobeProductCode(this.props.location[pathname]);
    if (window?.pageData?.page?.productName !== adobeProductCode) {
      window.pageData = { ...window.pageData, page: { ...window.pageData?.page, productName: adobeProductCode } };
    }
  };

  render() {
    return null;
  }
}

const mapStateToProps = createStructuredSelector({
  location: routerSelectors.getLocation
});

export default connect(mapStateToProps)(ELSWithModalService(LocationChangeHandler));
