import { ELSInlineLoader } from '@els/els-ui-common-react';
import classNames from 'classnames';

import { BentoBoxProps, ReportConfig } from 'components/common/bento-box/BentoBox';
import { ELSIcon } from 'components/common';
import { WELCOME_HEADER } from 'constants/app.constant';
import CentralizedLandingPageCards from 'components/features/centralized-landing-page/centralized-landing-page-cards/CentralizedLandingPageCards';
import Pill from 'components/common/pill/Pill';

import { PS_PILL_TITLES } from 'reports/ps/constants';

export const ProgramSolutionsNavMenu = ({ CW, HAD, PRD, PS, onClickOutSide }: BentoBoxProps) => {
  const handleOnNavigation = (config: ReportConfig) => {
    if (!config.disabled) {
      config.onNavigation();
      onClickOutSide();
    }
  };

  return (
    <div className="c-ps-nav-menu">
      <div className="c-ps-nav-menu__header-container">
        <div className="c-ps-nav-menu__header-image c-ps-nav-menu__header-image--left" />
        <div className="c-ps-nav-menu__header-title-container">
          <p className="c-ps-nav-menu__header-title">{WELCOME_HEADER}</p>
          <p className="c-ps-nav-menu__header-subtitle u-els-font-size-base">Your hub for essential performance analytics dashboards for Sherpath, HESI and Shadow Health</p>
        </div>
        <div className="c-ps-nav-menu__header-image c-ps-nav-menu__header-image--right" />
      </div>
      {PS.disabled ? (
        <CentralizedLandingPageCards onClickOutSide={onClickOutSide} />
      ) : (
        <div>
          <div className="c-ps-nav-menu__card-row-container">
            <button type="button" className="c-ps-nav-menu-card c-ps-nav-menu-card--top" onClick={() => handleOnNavigation(PS)}>
              <div className="c-ps-nav-menu-card__title c-ps-nav-menu-card__title--top">
                <span>Program Success Dashboard</span>
              </div>
              <p className="u-els-margin-bottom-1x">
                A holistic view of the nursing education journey, synthesizing insights from Sherpath, HESI, and Shadow Health to drive both program and student success.
              </p>
              <div className="c-ps-nav-menu__pill-row-container">
                <div className="c-ps-nav-menu__pill c-ps-nav-menu__pill--row-item">Connected insights from:</div>
                <Pill className="c-ps-nav-menu__pill c-ps-nav-menu__pill--green c-ps-nav-menu__pill--row-item">
                  <ELSIcon name="neuroimagery" prefix="gizmo" size="1x" customClass="c-ps-nav-menu__pill-icon u-custom-color-green-1" />
                  <span className="c-ps-nav-menu__pill-text">{PS_PILL_TITLES.SHERPATH}</span>
                </Pill>
                <Pill className="c-ps-nav-menu__pill c-ps-nav-menu__pill--orange c-ps-nav-menu__pill--row-item">
                  <ELSIcon name="stethoscope" prefix="gizmo" size="1x" customClass="c-ps-nav-menu__pill-icon u-custom-color-orange-2" />
                  <span className="c-ps-nav-menu__pill-text">{PS_PILL_TITLES.SHADOW_HEALTH}</span>
                </Pill>
                <Pill className="c-ps-nav-menu__pill c-ps-nav-menu__pill--blue">
                  <ELSIcon name="statistics" prefix="gizmo" size="1x" customClass="c-ps-nav-menu__pill-icon u-els-color-secondary" />
                  <span className="c-ps-nav-menu__pill-text">{PS_PILL_TITLES.HESI}</span>
                </Pill>
              </div>
            </button>
          </div>
          <div className="c-ps-nav-menu__card-row-container">
            {/* eslint-disable-next-line sonarjs/no-duplicate-string */}
            <button type="button" className={classNames('c-ps-nav-menu-card', { 'c-ps-nav-menu-card--disabled': HAD?.disabled })} onClick={() => handleOnNavigation(HAD)}>
              <div className="c-ps-nav-menu-card__title">HESI Readiness for NCLEX Dashboard</div>
              {HAD?.loading ? (
                <ELSInlineLoader />
              ) : (
                <>
                  <div className="c-ps-nav-menu__pill-row-container">
                    <Pill className="c-ps-nav-menu__pill c-ps-nav-menu__pill--blue">
                      <ELSIcon name="statistics" prefix="gizmo" size="1x" customClass="c-ps-nav-menu__pill-icon u-els-color-secondary" />
                      <span className="c-ps-nav-menu__pill-text">{PS_PILL_TITLES.HESI}</span>
                    </Pill>
                  </div>
                  <p className="u-els-margin-bottom-1x">Gain insights into student preparedness for the NCLEX exam, focusing on critical readiness indicators.</p>
                </>
              )}
            </button>
            <button type="button" className={classNames('c-ps-nav-menu-card', { 'c-ps-nav-menu-card--disabled': CW?.disabled })} onClick={() => handleOnNavigation(CW)}>
              <div className="c-ps-nav-menu-card__title">
                <span>Sherpath Performance & Grades Dashboard</span>
              </div>
              {CW?.loading ? (
                <ELSInlineLoader />
              ) : (
                <>
                  <div className="c-ps-nav-menu__pill-row-container">
                    <Pill className="c-ps-nav-menu__pill c-ps-nav-menu__pill--green">
                      <ELSIcon name="neuroimagery" prefix="gizmo" size="1x" customClass="c-ps-nav-menu__pill-icon u-custom-color-green-1" />
                      <span className="c-ps-nav-menu__pill-text">{PS_PILL_TITLES.SHERPATH}</span>
                    </Pill>
                  </div>
                  <p className="u-els-margin-bottom-1x">Gain insights into student engagement and the mastery of foundational skills throughout the course.</p>
                </>
              )}
            </button>
            <button type="button" className={classNames('c-ps-nav-menu-card', { 'c-ps-nav-menu-card--disabled': PRD?.disabled })} onClick={() => handleOnNavigation(PRD)}>
              <div className="c-ps-nav-menu-card__title">
                <span>Practice Readiness Dashboard</span>
              </div>
              {PRD?.loading ? (
                <ELSInlineLoader />
              ) : (
                <>
                  <div className="c-ps-nav-menu__pill-row-container">
                    <Pill className="c-ps-nav-menu__pill c-ps-nav-menu__pill--orange">
                      <ELSIcon name="stethoscope" prefix="gizmo" size="1x" customClass="c-ps-nav-menu__pill-icon u-custom-color-orange-2" />
                      <span className="c-ps-nav-menu__pill-text">{PS_PILL_TITLES.SHADOW_HEALTH}</span>
                    </Pill>
                  </div>
                  <p className="u-els-margin-bottom-1x">
                    Gain insights into student development of clinical judgment skills in Shadow Health, preparing them for hands-on clinical experience.
                  </p>
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
