import { ConnectedRouter } from 'connected-react-router';
import { createHashHistory } from 'history';
import React, { Component, Suspense } from 'react';
import { Redirect, Router, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AnyAction } from 'redux';
import { keyBy, mapValues, noop } from 'lodash';

import { ELSCommonUIConstants, ELSIdleProvider, ELSPageLoader, ELSSecurityRoutes, ELSTokenServiceRegistrar, ELSLoggingService } from '@els/els-ui-common-react';
import { ELSModalProvider } from '@els/els-component-modal-react';

import { AdobePageData, EOLSUser, SystemType, ColumnProps } from 'models';
import { appActions } from 'redux/ducks/app';
import { AppConstant } from 'constants/app.constant';
import { AppRoutes } from 'routes/app.routes';
import { checkPathMatch, checkPathMatchV2, sherEvolCourseFromEolsUsers } from 'helpers/app.helper';
import { configureStore } from 'redux/app.store';
import { fetchAllEolsUserByEolsUserId } from 'services/user-management.service';
import { fetchFeatureFlags as fetchFeatureFlagsService } from 'services/feature-flag.service';
import { filterActions } from 'redux/ducks/filter';
import { getCatalogs } from 'helpers/ui.helper';
import { IntegrationRoutes } from 'routes/integration.routes';
import { setDefaultAppConfig } from 'config/app.config';
import { UserHomeRoutes } from 'routes/user.home.routes';
import { withHTMLHeadSEO, ErrorBoundary, ELSAccessibilityFocusState, ELSRouterHelper } from 'components/common';
import AdobeAnalyticsInit from 'components/common/adobe-analytics-init/AdobeAnalyticsInit';
import LanguageProvider from 'components/common/language-provider/LanguageProvider';
import LocationChangeHandler from 'components/common/location-change/LocationChangeHandler';
import PendoInit from 'components/common/pendo-init/PendoInit';

import { courseActions } from 'reports/cw/redux/ducks/courses';
import { CourseSection } from 'reports/cw/models';
import { cwRoutePrefix } from 'reports/cw/constants/cw.constant';
import { CwRoutes } from 'reports/cw/routes/cw.routes';
import { DEFAULT_PROGRAM_ID, HAD_PATHS } from 'reports/had/constants/had.constant';
import { EhrRoutes } from 'reports/ehr/routes/ehr.routes';
import { fetchCourseSectionInfo } from 'reports/cw/services/courses.service';
import { fetchCWAssignmentByCourseSectionId } from 'reports/cw/services/report.service';
import { fetchEAQTopicMappedHesiExam } from 'reports/ehr/services/api.service';
import { fetchPrograms, fetchRelatedUserInfo } from 'reports/had/services/had.service';
import { hadActions } from 'reports/had/redux/ducks/had';
import { HadRoutes } from 'reports/had/routes/had.routes';
import { HesiNGRoutes } from 'reports/hesi-ng/routes/hesi-ng.routes';
import { PsRoutes } from 'reports/ps/routes/ps.routes';
import { RESOURCE_TYPES } from 'reports/ps/constants';
import { SH_PATHS } from 'reports/sh/constants/sh.constant';
import { ShRoutes } from 'reports/sh/routes/sh.routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { initDB } from '../indexedDB/db';

import '../assets/main.scss';

setDefaultAppConfig();
ELSTokenServiceRegistrar.initializeFromReload();

// timeout 1 day
const timeout = 86400000;
export const history = createHashHistory();
export const store = configureStore(history);

const redirectToUserHomepage = ({ user, role }) => {
  const userHome = UserHomeRoutes.getUserHome();
  history.push({ state: user, pathname: userHome[role] });
};

// TODO: review some of these exported functions, determine if they're needed, and move them to a common file
export const fetchFeatureFlags = () => {
  if (ELSTokenServiceRegistrar.getToken()) {
    store.dispatch(appActions.setIsFeatureFlagsLoading(true));
    fetchFeatureFlagsService()
      .then(payload => {
        store.dispatch(appActions.setFeatureFlags(payload.data));
      })
      .finally(() => {
        store.dispatch(appActions.setIsFeatureFlagsLoading(false));
      });
  }
};

export const setEAQTopicMappedHesiExamByCourseId = async () => {
  const appState = store.getState()[AppConstant.reduxResources.APP_STATE];
  const { courses = [] } = appState;

  // filter all ISBNs from allEolsCourses
  const entitlements = courses.flatMap(c => c.entitlements);
  // create and isbn list and remove duplicates with a new set
  const isbnList: string[] = Array.from(new Set(entitlements.map(etm => etm.isbn)));
  // get results (taxonomy) per ISBN
  const eaqTopicMappedHesiExamByISBN = {};
  const eaqTopicMappedListTwo = await Promise.allSettled(
    isbnList?.map((isbn: string) => {
      return fetchEAQTopicMappedHesiExam([isbn]);
    })
  );
  eaqTopicMappedListTwo.forEach(({ value }, index) => {
    if (value.length > 0) {
      eaqTopicMappedHesiExamByISBN[isbnList[index]] = value;
    }
  });
  store.dispatch(appActions.setEaqTopicMappedHesiExamByISBN(eaqTopicMappedHesiExamByISBN));
};

export const setEAQTopicMappedHesiExam = (courseSection: CourseSection) => {
  const isbnList = courseSection?.entitlements?.map(etm => etm.isbn) || [];
  store.dispatch(appActions.setIsEAQTopicMappedHesiExamLoading(true));
  fetchEAQTopicMappedHesiExam(isbnList)
    .then(res => {
      store.dispatch(appActions.setEAQTopicMappedHesiExam(res));
    })
    .finally(() => {
      store.dispatch(appActions.setIsEAQTopicMappedHesiExamLoading(false));
    });
};

const checkCourseHasCWData = async (userId: number, courseSections?: CourseSection[]) => {
  store.dispatch(appActions.setIsCWBentoBoxLinkShow(false));
  store.dispatch(appActions.setIsCWBentoBoxLinkLoading(true));
  fetchRelatedUserInfo(userId)
    .then(res => {
      const eolsUsers = res.data.map(x => x.eolsUser);
      const courseSectionsForAllEolsUsers = eolsUsers.map(x => x.courseSections);
      if (courseSectionsForAllEolsUsers.length) {
        const dataExists = courseSectionsForAllEolsUsers.some(thisUserCourseSections => thisUserCourseSections.length);

        if (dataExists) {
          store.dispatch(appActions.setIsCWBentoBoxLinkShow(true));
        }
      }
    })
    .catch(() => {
      if (courseSections && courseSections.length) {
        const hasCWCourse = courseSections.some(cs => cs.courseId);
        if (hasCWCourse) {
          store.dispatch(appActions.setIsCWBentoBoxLinkShow(true));
        }
      }
    })
    .finally(() => store.dispatch(appActions.setIsCWBentoBoxLinkLoading(false)));
};

export const setCatalogAndAssignments = async (courses, allEolsUsers: EOLSUser[], courseSectionInfo: CourseSection) => {
  const [{ catalog }, { data }] = await Promise.all([getCatalogs(courses, allEolsUsers, RESOURCE_TYPES.REVIEW_QUIZ.id), fetchCWAssignmentByCourseSectionId(courseSectionInfo.id)]);
  store.dispatch(appActions.setCatalog(catalog));
  store.dispatch(appActions.setAssignments(data));
};

export const setCourses = async (loggedInUser: EOLSUser, courseSectionInfo: CourseSection) => {
  const isHADPath = checkPathMatch(Object.values(HAD_PATHS));
  const userId = loggedInUser?.id || loggedInUser?.userId;
  const { data = [] } = await fetchAllEolsUserByEolsUserId(userId, SystemType.EVOLVETYPE);
  if (data?.length) {
    store.dispatch(appActions.setAllEolsUsers(data));
  }
  const courseSections = sherEvolCourseFromEolsUsers(data);
  let courses = courseSections;
  if (isHADPath || !loggedInUser?.courseSections) {
    store.dispatch(appActions.setCourses(courseSections));
    checkCourseHasCWData(userId, courseSections);
  } else {
    const checkCourseList = mapValues(keyBy(loggedInUser?.courseSections || [], 'id'), 'id');
    const filteredCourseSections = courseSections.filter(c => !checkCourseList[c.id]);
    courses = loggedInUser?.courseSections?.concat(filteredCourseSections);
    store.dispatch(appActions.setCourses(courses));
    checkCourseHasCWData(userId, courses);
  }
  setEAQTopicMappedHesiExamByCourseId();
  const isCWInstructorPath = checkPathMatchV2(Object.values(cwRoutePrefix), false);
  if (isCWInstructorPath) {
    setCatalogAndAssignments(courses, data, courseSectionInfo);
  }
};

export const setCourseSectionInfo = async (courseId?: number, onComplete = noop) => {
  if (ELSTokenServiceRegistrar.getToken()) {
    const appState = store.getState()[AppConstant.reduxResources.APP_STATE];
    const selectedCourseId: number = courseId || appState.selectedCourse;
    store.dispatch(courseActions.setIsCourseFetching(true));
    store.dispatch(appActions.setIsEAQTopicMappedHesiExamLoading(true));
    fetchCourseSectionInfo(selectedCourseId)
      .then(response => {
        const courseSection = response.data;
        store.dispatch(courseActions.setCourseSectionInfo(courseSection));
        try {
          setEAQTopicMappedHesiExam(courseSection);
          const isSHPath = checkPathMatch(Object.values(SH_PATHS));
          if (!isSHPath) {
            setCourses(appState.loggedInUser, courseSection);
          }
        } catch (err) {
          ELSLoggingService.error('App.tsx', `Error while attempting to fetch setEAQTopicMappedHesiExam, error: ${err.message}`);
        }
        onComplete();
      })
      .finally(() => {
        store.dispatch(courseActions.setIsCourseFetching(false));
      });
  }
};

export const setHADProgram = () => {
  const isHADPath = checkPathMatch(Object.values(HAD_PATHS));
  if (isHADPath) {
    return;
  }

  store.dispatch(appActions.setIsCWLinkHADAndEHRChecking(true));
  fetchPrograms()
    .then(res => {
      store.dispatch(hadActions.setPrograms(res || []));
    })
    .catch(e => {
      ELSLoggingService.error('App.tsx', `Error while attempting to fetch programs, error: ${e.message}`);
    })
    .finally(() => {
      store.dispatch(appActions.setIsCWLinkHADAndEHRChecking(false));
    });
};

const resetData = () => {
  store.dispatch(appActions.setIsLaunchedFromAnotherApp(false));
  store.dispatch(appActions.setLinkId(null));
  store.dispatch(appActions.setIsBackToPreviousAppIconShown(false));
  store.dispatch(hadActions.setProgramId(DEFAULT_PROGRAM_ID));
  store.dispatch(hadActions.setPrograms([]));
  store.dispatch(filterActions.setCWEngagementAssignmentTypeDropdownId(null));
};

export const initializeAppByAdminBackdoor = ({ eolsUser, roleId, courseId, isbn }) => {
  store.dispatch(appActions.setLoggedInUser(eolsUser));
  store.dispatch(appActions.setUserRole(roleId));
  store.dispatch(appActions.setIsbn(isbn));
  store.dispatch(appActions.setSelectedCourse(courseId));
  store.dispatch(appActions.setCourses(eolsUser.courseSections));
  resetData();
  redirectToUserHomepage({ user: eolsUser, role: roleId });
  setCourseSectionInfo(courseId);
  setHADProgram();
  fetchFeatureFlags();
  store.dispatch((appActions.checkEHREnabled() as unknown) as AnyAction);
};

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      column: ColumnProps;
    }
  }
  interface Window {
    education: object;
    pendo: {
      initialize: Function;
    };
    pageData: AdobePageData;
    pageDataTracker: {
      getVisitorId: Function;
    };
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // Set refetchOnWindowFocus globally
      retry: 1, // retry once if failed
      retryDelay: 2000, // retry after 1 second
      networkMode: 'always'
    }
  }
});

class App extends Component {
  componentDidMount() {
    this.handleInitDB();
  }

  handleInitDB = async () => {
    await initDB().catch(error => {
      ELSLoggingService.warn(error);
    });
  };

  handleSessionTimeout = () => console.log('implement soon');

  render() {
    const HTMLHeadSEOComponent = withHTMLHeadSEO({})(null);
    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <LanguageProvider>
              <ELSModalProvider>
                <>
                  <LocationChangeHandler />
                  <ErrorBoundary>
                    <HTMLHeadSEOComponent />
                    <AdobeAnalyticsInit />
                    <PendoInit />
                    <Router history={history}>
                      <Suspense fallback={<ELSPageLoader />}>
                        <Switch>
                          {ELSSecurityRoutes.getSecurityRoutes(initializeAppByAdminBackdoor)
                            .concat(IntegrationRoutes.getRoutes())
                            .concat(HesiNGRoutes.getRoutes())
                            .concat(HadRoutes.getRoutes())
                            .concat(ShRoutes.getRoutes())
                            .concat(CwRoutes.getRoutes())
                            .concat(EhrRoutes.getRoutes())
                            .concat(PsRoutes.getRoutes())
                            .concat(AppRoutes.getRoutes())
                            .map(route => ELSRouterHelper.createRoute(route))}
                          <Redirect from="/" to={`/${ELSCommonUIConstants.security.States.PageNotFound}`} />
                        </Switch>
                      </Suspense>
                    </Router>
                    <ELSAccessibilityFocusState />
                    <ELSIdleProvider timeout={timeout} onSessionTimeout={this.handleSessionTimeout} />
                  </ErrorBoundary>
                </>
              </ELSModalProvider>
            </LanguageProvider>
          </ConnectedRouter>
        </Provider>
      </QueryClientProvider>
    );
  }
}

export default App;
